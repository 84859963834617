<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>

    <el-tabs v-model="activeName" @tab-click="handleClickTabs">
      <el-tab-pane label="基本信息" name="1"></el-tab-pane>
      <el-tab-pane label="规格/库存" name="2"></el-tab-pane>
      <el-tab-pane label="商品详情" name="3"></el-tab-pane>
    </el-tabs>
    <el-form
      ref="form"
      :model="formData"
      :disabled="formData.status == 1"
      label-width="100px"
    >
      <!-- 基本信息 -->
      <div v-show="activeName == 1" class="baseInfo-1">
        <el-form-item label="商品标题" prop="goodsTitle" :rules="rules">
          <el-input
            v-model="formData.goodsTitle"
            size="small"
            placeholder="请输入商品标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品副标题" prop="subTitle" :rules="rules">
          <el-input
            v-model="formData.subTitle"
            size="small"
            placeholder="请输入商品副标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品标签" prop="goodsTagList" :rules="rules">
          <div class="goods-tags">
            <el-tag
              :key="tag"
              v-for="tag in formData.goodsTagList"
              :closable="formData.status != 1"
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput"
              >+ 新增标签</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="详情标题" prop="detailTitle" :rules="rules">
          <el-input
            type="textarea"
            v-model="formData.detailTitle"
            size="small"
            placeholder="详情标题"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="商品分类"
          class="goods-category"
          prop="categoryId"
          :rules="rules"
        >
          <SelectGoodsCategory
            v-model="formData.categoryId"
            ref="goodsCategory"
          ></SelectGoodsCategory>
          <!-- <div class="tips-btn">
                      <span @click="$router.push({ name: 'CategoryAdd', query: { showCategory: true } })">去新增</span>
                      <span @click="$refs.goodsCategory.getTree()">刷新</span>
                    </div> -->
        </el-form-item>
        <el-form-item label="成色" prop="goodsPurity" :rules="rules">
          <SelectDict
            v-model="formData.goodsPurity"
            dictCode="goods:purity"
          ></SelectDict>
        </el-form-item>
        <el-form-item label="品牌" prop="brand" :rules="rules">
          <el-select
            v-model="formData.brand"
            placeholder="请选择"
            size="small"
            clearable
          >
            <el-option
              v-for="item in brandList"
              :key="item.enumCode"
              :label="item.enumText"
              :value="item.enumCode"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="发货方式" prop="deliveryTypeList" :rules="rules">
          <el-checkbox-group v-model="formData.deliveryTypeList">
            <el-checkbox :label="1">物流发货</el-checkbox>
            <el-checkbox :label="3">虚拟发货</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="套餐类型" prop="goodsComboList" :rules="rules">
          <SelectDictCheckbox
            v-model="formData.goodsComboList"
            dictCode="goods:combo"
          ></SelectDictCheckbox>
        </el-form-item>

        <el-form-item
          v-show="formData.goodsComboList.indexOf('buyoutReturnAtTerm') > -1"
          :rules="rules"
          label="账单期数"
          prop="billPeriodNum"
        >
          <el-input
            v-model.number="formData.billPeriodNum"
            @input="changePeriodNum"
            clearable
            size="small"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="首付期数"
          v-show="
            formData.billPeriodNum &&
            formData.goodsComboList.indexOf('buyoutReturnAtTerm') > -1
          "
        >
          <el-checkbox-group
            v-model="formData.prepayPeriodList"
            style="width: 550px"
          >
            <el-checkbox
              v-for="item in formData.billPeriodNum"
              :key="item"
              :label="item"
              :value="item"
            >
              第{{ item }}期
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          v-show="formData.goodsComboList.indexOf('buyoutReturnAtTerm') > -1"
          :rules="rules"
          label="付款周期"
          class="period"
          prop="periodInterval"
        >
          <el-input
            style="width: 250px; margin-right: 20px"
            v-model.number="formData.periodInterval"
            size="small"
            placeholder="请输入"
          ></el-input>
          <SelectDict
            v-model="formData.periodUnit"
            dictCode="bill:periodUnit"
          ></SelectDict>
        </el-form-item>
        <el-form-item
          v-if="formData.goodsComboList.indexOf('payAfterUse') > -1"
          label="后付天数"
          :rules="rules"
        >
          <el-input
            v-model="formData.delayDayNum"
            size="small"
            placeholder="请输入"
            clear
          ></el-input>
        </el-form-item>
      </div>
      <!-- 规格/库存 -->
      <div v-show="activeName == 2" class="baseInfo-2">
        <!-- <el-form-item label="价格系数：" prop="priceRate" :rules="rules">
          <el-input style="width:500px;" v-model="formData.priceRate" size="small" placeholder="请输入系数"></el-input>
        </el-form-item> -->
        <!--        <el-form-item label="保险金" prop="insurancePrice">-->
        <!--          <el-input style="width:500px;" v-model="formData.insurancePrice" size="small" placeholder="请输入保险金"></el-input>-->
        <!--        </el-form-item>-->
        <!-- <el-button @click="addSku" style="margin:10px 0 20px" type="primary" size="small" icon="el-icon-plus">新增规格</el-button>
                <div class="goods-sku">
                  <div class="sku-title">
                    <span>序号</span>
                    <span>规格标题</span>
                    <span>官方价格</span>
                    <span>库存数量</span>
                    <span>规格封面</span>
                    <span>规格属性</span>
                    <span>操作</span>
                  </div>
                  <div class="sku-content" v-for="(sku, skuIndex) in formData.skuList" :key="skuIndex">
                    <span>{{ skuIndex + 1 }}</span>
                    <span>
                      <el-form-item label="" label-width="0">
                        <el-input style="width:100%" size="small" v-model="sku.skuTitle"></el-input>
                      </el-form-item>
                    </span>
                    <span>
                      <el-form-item label="" label-width="0">
                        <el-input style="width:100%" size="small" v-model="sku.officialPrice"></el-input>
                      </el-form-item>
                    </span>
                    <span>
                      <el-form-item label="" label-width="0">
                        <el-input style="width:100%" size="small" v-model="sku.stockNum"></el-input>
                      </el-form-item>
                    </span>
                    <span>
                      <FileUpload :name.sync="sku.coverImage" :url="sku.coverImageUrl"></FileUpload>
                    </span>
                    <span class="sku-attr">
                      <div class="attr-title">
                        <span>属性名</span>
                        <span>属性值</span>
                        <span></span>
                      </div>
                      <div class="attr-value" v-for="(attr, attrIndex) in sku.skuAttrList" :key="attrIndex">
                        <span>
                          <el-input size="mini" v-model="attr.group"></el-input>
                        </span>
                        <span>
                          <el-input size="mini" v-model="attr.value"></el-input>
                        </span>
                        <span>
                          <el-link @click="deleteSkuAttr(skuIndex, attrIndex)" type="primary">删除</el-link>
                        </span>
                      </div>
                      <div style="width:100%">
                        <el-button @click="addSkuAttr(skuIndex)" type="primary" plain icon="el-icon-plus" size="mini">新增属性</el-button>
                      </div>
                    </span>
                    <span>
                      <el-link @click="deleteSku(skuIndex)" type="primary">删除规格</el-link>
                    </span>
                  </div>
                  <div></div>
                </div> -->

        <Sku
          ref="sku"
          :status="formData.status"
          :priceRate="formData.priceRate"
          :skuAttrGroupList="formData.skuAttrGroupList"
          :skuFormList="formData.skuList"
          @addStock="addStock"
        ></Sku>
        <!-- <el-empty v-show="!formData.skuList.length > 0" description="暂无规格"></el-empty> -->
      </div>
      <!-- 商品详情 -->
      <div v-show="activeName == 3" class="baseInfo-3">
        <!-- <el-form-item label="买断金" prop="buyoutPrice" :rules="rules">
          <el-input v-model="formData.buyoutPrice" size="small" placeholder="请输入买断金"></el-input>
        </el-form-item>
        <el-form-item label="溢价金">
          <el-input v-model="formData.overflowPrice" size="small" placeholder="请输入溢价金"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="保险金" prop="insurancePrice" :rules="rules">
          <el-input v-model="formData.insurancePrice" size="small" placeholder="请输入保险金"></el-input>
        </el-form-item> -->
        <!--        <el-form-item label="基础销量" prop="baseSaleNum" :rules="rules">-->
        <!--          <el-input v-model="formData.baseSaleNum" size="small" placeholder="请输入基础销量"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="封面图" prop="coverImage" :rules="rules">
          <!-- <file-upload :isMulti="true" @fileUpload="aaaa"></file-upload> -->
          <FileUpload
            :name.sync="formData.coverImage"
            :url="formData.coverImageUrl"
          ></FileUpload>
        </el-form-item>
        <el-form-item label="主图" prop="masterImageList" :rules="rules">
          <FileUpload
            :isMulti="true"
            :name.sync="formData.masterImageList"
            :url="formData.masterImageUrlList"
          ></FileUpload>
        </el-form-item>
        <el-form-item label="详情图" prop="detailImageList" :rules="rules">
          <FileUpload
            :isMulti="true"
            :name.sync="formData.detailImageList"
            :url="formData.detailImageUrlList"
          ></FileUpload>
        </el-form-item>
      </div>
      <el-form-item
        label=""
        label-width="0"
        style="margin-top: 20px; text-align: center"
      >
        <el-button
          v-show="activeName > 1"
          type="primary"
          size="small"
          @click="prev"
          >上一步</el-button
        >
        <el-button
          v-show="activeName == 3"
          type="primary"
          size="small"
          @click="save"
          >提交</el-button
        >
        <el-button
          v-show="activeName < 3"
          type="primary"
          size="small"
          @click="next"
          >下一步</el-button
        >
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
    <!-- 弹窗 -->
    <el-dialog title="更新库存" :visible.sync="dialogVisible" width="700px">
      <el-form
        ref="stockForm"
        :model="stockFormData"
        :rules="stockFormRules"
        label-width="80px"
      >
        <el-form-item label="库存" prop="stockNum">
          <el-input
            v-model.number="stockFormData.stockNum"
            size="small"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="stockFormData.remark"
            :rows="5"
            maxlength="200"
            show-word-limit
            size="small"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="cancelStock" size="small">取 消</el-button>
          <el-button type="primary" @click="saveStock" size="small"
            >确 定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import {
  editGoodsAPI,
  addGoodsAPI,
  getGoodsDetailAPI,
  addGoodsStockAPI,
  getBrandListAPI,
} from "./api";
import { EnumGoodsType } from "@/enum/dict/index.js";
import SelectGoodsCategory from "@/views/components/select-goods-category.vue";
import SelectDictCheckbox from "@/views/components/select-dict-checkbox.vue";
import SelectDict from "@/views/components/select-dict.vue";
import FileUpload from "@/components/file-upload/index.vue";
import Sku from "./sku-form.vue";

export default {
  name: "AddOrEdit",
  components: {
    FileUpload,
    SelectGoodsCategory,
    SelectDictCheckbox,
    SelectDict,
    Sku,
  },
  data() {
    return {
      activeName: "1",
      formData: {
        categoryId: null,
        goodsTitle: "",
        subTitle: "",
        detailTitle: "",
        goodsTagList: [],
        brand: "",
        goodsComboList: [],
        // priceRate: 100,
        // buyoutPrice: '',
        // overflowPrice: '',
        insurancePrice: null,
        periodInterval: null,
        periodUnit: null,
        billPeriodNum: null,
        prepayPeriodList: [],
        coverImage: "",
        coverImageUrl: "",
        masterImageList: [],
        masterImageUrlList: [],
        detailImageList: [],
        detailImageUrlList: [],
        isRecommend: 1,
        skuList: [],
        goodsPurity: "",
        deliveryTypeList: [],
        delayDayNum: "",
      },
      // goodsTagList: ['标签一', '标签二', '标签三'],
      inputVisible: false,
      inputValue: "",
      rules: [{ required: true, message: "该项不能为空", trigger: "blur" }],
      stockFormData: {
        id: "",
        stockNum: 0,
        remark: "",
      },
      stockFormRules: {
        stockNum: [{ required: true, message: "请输入库存", trigger: "blur" }],
      },
      dialogVisible: false,
      brandList: [],
    };
  },

  mounted() {
    this.getBrandList();

    if (this.$route.params.id) {
      this.getGoodsDetail();
    }
  },
  computed: {
    validateTab() {
      let active = "";
      const {
        goodsTitle,
        subTitle,
        goodsTagList,
        detailTitle,
        categoryId,
        brand,
        goodsComboList,
        coverImage,
        masterImageList,
        detailImageList,
        goodsPurity,
        deliveryTypeList,
      } = this.formData;
      const tab1 =
        goodsTitle &&
        subTitle &&
        goodsTagList.length &&
        detailTitle &&
        categoryId &&
        brand &&
        goodsComboList.length &&
        goodsPurity &&
        deliveryTypeList.length;
      const tab3 =
        coverImage && masterImageList.length && detailImageList.length;
      const tab2 = this.formData.skuList.length > 0;

      if (!tab1) {
        return (active = "1");
      } else if (!tab2) {
        return (active = "2");
      } else if (!tab3) {
        return (active = "3");
      }
      return active;
    },
  },
  watch: {
    "formData.billPeriodNum": {
      handler(newV, oldV) {
        if (newV) {
          console.log(newV);
          // this.formData.prepayPeriodList = []
        }
      },
    },
  },
  methods: {
    async getBrandList() {
      this.brandList = await getBrandListAPI({
        goodsType: EnumGoodsType.C3,
      });
    },
    // 保存商品
    save() {
      this.$refs.form.validate((valid) => {
        this.formData.skuList = this.$refs.sku.skuList;
        this.formData.rentPrice = this.$refs.sku.rentPrice;

        if (this.activeName == 3) {
          if (this.formData.id) {
            // 编辑
            editGoodsAPI(this.formData).then(() => {
              this.$message.success("编辑成功");
              this.$router.back();
            });
          } else {
            // 新增
            addGoodsAPI(this.formData).then(() => {
              this.$message.success("新增成功");
              this.$router.back();
            });
          }
        } else {
          this.activeName = this.validateTab;
          if (this.validateTab == 2) this.$message.error("商品规格必填！");
        }
      });
    },
    // 新增一个规格
    addSku() {
      let data = {
        skuTitle: "",
        officialPrice: "",
        stockNum: 0,
        coverImage: "",
        skuAttrList: [],
      };
      this.formData.skuList.push(data);
    },
    // 删除一个规格
    deleteSku(skuIndex) {
      this.formData.skuList.splice(skuIndex, 1);
    },
    // 新增一个属性
    addSkuAttr(skuIndex) {
      let attr = {
        group: "",
        value: "",
      };
      this.formData.skuList[skuIndex].skuAttrList.push(attr);
    },
    // 删除一个属性
    deleteSkuAttr(skuIndex, attrIndex) {
      this.formData.skuList[skuIndex].skuAttrList.splice(attrIndex, 1);
    },
    // 商品详情
    async getGoodsDetail() {
      this.formData = await getGoodsDetailAPI(this.$route.params.id);
    },
    handleClickTabs() {},
    handleClose(tag) {
      this.formData.goodsTagList.splice(
        this.formData.goodsTagList.indexOf(tag),
        1,
      );
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.formData.goodsTagList.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    next() {
      this.activeName++;
      this.activeName = this.activeName + "";
    },
    prev() {
      this.activeName = this.activeName - 1;
      this.activeName = this.activeName + "";
    },
    addStock({ id, stockNum }) {
      this.dialogVisible = true;
      this.stockFormData.id = id;
      this.stockFormData.stockNum = stockNum;
    },
    cancelStock() {
      this.dialogVisible = false;
      this.stockFormData = { id: "", stockNum: 0, remark: "" };
    },
    saveStock() {
      this.$refs.stockForm.validate((valid) => {
        console.log("valid", valid);
        if (valid) {
          addGoodsStockAPI(this.stockFormData).then(() => {
            this.dialogVisible = false;
            this.$message.success("增加成功");
            this.getGoodsDetail();
            this.stockFormData = { id: "", stockNum: 0, remark: "" };
          });
        }
      });
    },
    changePeriodNum() {
      this.formData.prepayPeriodList = [];
    },
  },
  activated() {},
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .baseInfo-1,
    .baseInfo-3 {
      .el-input,
      .el-textarea {
        width: 500px;
      }
    }

    .el-form-item {
      .goods-tags {
        width: 500px;

        .el-tag + .el-tag {
          margin-left: 10px;
        }

        .button-new-tag {
          margin-left: 10px;
          height: 32px;
          line-height: 30px;
          padding-top: 0;
          padding-bottom: 0;
        }

        .input-new-tag {
          width: 90px;
          margin-left: 10px;
          vertical-align: bottom;
        }
      }
    }

    .period {
      .el-select,
      .el-input {
        width: 240px !important;
      }
    }

    .goods-category {
      // margin-bottom: 0;
      .tips-btn {
        span {
          cursor: pointer;
          margin-right: 10px;
          color: #1890ff;
          font-size: 12px;
        }
      }
    }

    .goods-sku {
      min-width: 1000px;
      // max-width: 1300px;
      .sku-title,
      .sku-content {
        width: 100%;
        display: flex;
        padding: 10px;
        justify-content: space-between;

        & > span {
          flex: 2;

          &:nth-child(1) {
            flex: 1;
          }

          &:nth-child(2) {
            flex: 3;
          }

          &:nth-child(5) {
            flex: 1.5;
          }

          &:nth-child(6) {
            flex: 3;
          }

          &:nth-child(7) {
            flex: 1;
          }
        }

        .el-form-item {
          margin-bottom: 0;
        }
      }

      .sku-title {
        background-color: #f4f5f9;

        & > span {
          padding-right: 20px;
          // &:last-child {
          //   display: flex;
          //   justify-content: space-between;
          // }
        }
      }

      .sku-content {
        border-bottom: 1px solid #e4e5e9;
        background-color: #fbfbfb;

        & > span {
          margin: auto;
          padding-right: 20px;
        }

        .sku-attr {
          display: flex;
          flex-direction: column;

          .attr-title,
          .attr-value {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            & > span {
              flex: 1;
              display: flex;
              align-items: center;

              &:nth-child(-n + 2) {
                margin-right: 10px;
                flex: 2;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
  }

  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }

  .goods-sku,
  .baseInfo-2 {
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
    }

    .avatar {
      width: 60px;
      height: 60px;
      display: block;
    }
  }
}
</style>
